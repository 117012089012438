import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['checkTrain', 'checkCar', 'areas', 'times', 'fares', 'faresOuter', 'prices', 'fareHeader', 'result', 'checkTime30', 'checkTime60', 'checkFare3000', 'checkPrice150', 'checkPrice300', 'schoolRatingsUa', 'rents', 'checkRent750', 'checkRent1250',
  'crime', 'green', 'broadband']

  toggleExtraFilters(e) {
    e.preventDefault()
    let isExtraFilterHidden = document.getElementById("filters-expand-outer").classList.toggle('hide')
    e.currentTarget.innerHTML = isExtraFilterHidden ? 'Show filters' : 'Close filters'
  }

  hideFares(index, hide=true){
    if (hide) {
      this.fareHeaderTarget.classList.add('hide')
      this.faresTargets[index].classList.add('hide')
    }
    else {
      this.fareHeaderTarget.classList.remove('hide')
      this.faresTargets[index].classList.remove('hide')
    }
  }

  upperTimeLimit() {
    if (this.checkTime60Target.checked) {
      return 60.0
    }
    else if (this.checkTime30Target.checked) {
      return 30.0
    }
    else {
      return Infinity
    }
  }

  upperFareLimit() {
    return this.checkFare3000Target.checked ? 3000.0 : Infinity
  }

  upperPriceLimit() {
    if (this.checkPrice300Target.checked) {
      return 300000.0
    }
    else if (this.checkPrice150Target.checked) {
      return 150000.0
    }
    else {
      return Infinity
    }
  }

  upperRentLimit() {
    if (this.checkRent750Target.checked) {
      return 750.0
    }
    else if (this.checkRent1250Target.checked) {
      return 1250.0
    }
    else {
      return Infinity
    }
  }

  hideRows(index, rowTime, rowFare, rowPrice, rowRent) {
    let showTime = rowTime <= this.upperTimeLimit() ? true : false
    let showFare = rowFare <= this.upperFareLimit() ? true : false
    let showPrice = rowPrice <= this.upperPriceLimit() ? true : false
    let showRent = rowRent <= this.upperRentLimit() ? true: false

    if (showTime && showFare && showPrice && showRent) {
      this.faresOuterTargets[index].classList.remove('hide')
      this.timesTargets[index].classList.remove('hide')
      this.pricesTargets[index].classList.remove('hide')
      this.rentsTargets[index].classList.remove('hide')
      this.areasTargets[index].classList.remove('hide')

      try {
        this.schoolRatingsUaTargets[index].classList.remove('hide')
      } catch(e) {
      }

      try {
        this.greenTargets[index].classList.remove('hide')
      } catch(e) {
      }

      try {
        this.crimeTargets[index].classList.remove('hide')
      } catch(e) {
      }


      try {
        this.broadbandTargets[index].classList.remove('hide')
      } catch(e) {
      }

    }
    else {
      this.faresOuterTargets[index].classList.add('hide')
      this.timesTargets[index].classList.add('hide')
      this.pricesTargets[index].classList.add('hide')
      this.rentsTargets[index].classList.add('hide')
      this.areasTargets[index].classList.add('hide')
      try {
        this.schoolRatingsUaTargets[index].classList.add('hide')
      } catch(e) {
      }

      try {
        this.greenTargets[index].classList.add('hide')
      } catch(e) {
      }

      try {
        this.crimeTargets[index].classList.add('hide')
      } catch(e) {
      }

      try {
        this.broadbandTargets[index].classList.add('hide')
      } catch(e) {
      }
    }
  }


  filter() {
    this.timesTargets.forEach((el, i) => {
      let rowTime = Infinity

      if ((this.checkTrainTarget.checked && this.checkCarTarget.checked) || (!this.checkTrainTarget.checked && !this.checkCarTarget.checked)){
        rowTime = Math.min(parseInt(el.dataset.timeCar) || Infinity, parseInt(el.dataset.timeTrain) || Infinity) || ''
        this.hideFares(i, false)
      }
      else if (this.checkTrainTarget.checked) {
        rowTime = parseInt(el.dataset.timeTrain) || ''
        this.hideFares(i, false)
      }
      else {
        rowTime = parseInt(el.dataset.timeCar) || ''
        this.hideFares(i, true)
      }

      el.innerHTML = rowTime
      let rowFare = Math.min(parseFloat(el.dataset.trainFare) || Infinity)
      let rowPrice = Math.min(parseFloat(el.dataset.housePrice) || Infinity)
      let rowRent = Math.min(parseFloat(el.dataset.rent) || Infinity)
      this.hideRows(i, rowTime, rowFare, rowPrice, rowRent)
    })
  }



  bookmark() {
    const id = window.location.href.split('#')[1]
    if (id) {
      try {
        document.getElementById(id).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
      } catch(e) {

      }
    }
  }


  connect() {
    this.filter()
    this.bookmark()
  }


  // removed all sorting options. Implement later
  

}
