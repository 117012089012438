import { Controller } from "stimulus"
import Chartist from "chartist"

export default class extends Controller {
  static targets = ['prices', 'charts']

  drawChart() {
    let options = {
      height:'1em',
      width:'8ex',
      showPoint: false,
      fullWidth:true,
      chartPadding: {top: 0,right: 0,bottom: 0,left: 0},
      axisX: {showGrid: false, showLabel: false, offset: 0},
      axisY: {showGrid: false, showLabel: false, offset: 0}
    }

    this.pricesTargets.forEach((el, i) => {
      let values = []
      try {
        values = JSON.parse(el.getAttribute('data-hpi-values')).map(x => parseFloat(x))
      }
      catch (e) {
        console.log(e)
      }

      new Chartist.Line(this.chartsTargets[i], {series: [values]}, options)
    })
  }


  connect() {
    this.drawChart()
  }

}
