import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['checkTrain', 'checkCar', 'areas', 'times', 'fares', 'faresOuter', 'prices', 'fareHeader', 'result', 'sortables', 'sortIndex', 'checkTime30', 'checkTime60', 'checkFare3000', 'checkPrice150', 'checkPrice300', 'schoolRatingsUa', 'rents', 'checkRent750', 'checkRent1250']

  toggleExtraFilters(e) {
    e.preventDefault()
    let isExtraFilterHidden = document.getElementById("filters-expand-outer").classList.toggle('hide')
    e.currentTarget.innerHTML = isExtraFilterHidden ? 'Show filters' : 'Close filters'
  }

  hideFares(index, hide=true){
    if (hide) {
      this.fareHeaderTarget.classList.add('hide')
      this.faresTargets[index].classList.add('hide')
    }
    else {
      this.fareHeaderTarget.classList.remove('hide')
      this.faresTargets[index].classList.remove('hide')
    }
  }

  upperTimeLimit() {
    if (this.checkTime60Target.checked) {
      return 60.0
    }
    else if (this.checkTime30Target.checked) {
      return 30.0
    }
    else {
      return Infinity
    }
  }

  upperFareLimit() {
    return this.checkFare3000Target.checked ? 3000.0 : Infinity
  }

  upperPriceLimit() {
    if (this.checkPrice300Target.checked) {
      return 300000.0
    }
    else if (this.checkPrice150Target.checked) {
      return 150000.0
    }
    else {
      return Infinity
    }
  }

  upperRentLimit() {
    if (this.checkRent750Target.checked) {
      return 750.0
    }
    else if (this.checkRent1250Target.checked) {
      return 1250.0
    }
    else {
      return Infinity
    }
  }

  hideRows(index, rowTime, rowFare, rowPrice, rowRent) {
    let showTime = rowTime <= this.upperTimeLimit() ? true : false
    let showFare = rowFare <= this.upperFareLimit() ? true : false
    let showPrice = rowPrice <= this.upperPriceLimit() ? true : false
    let showRent = rowRent <= this.upperRentLimit() ? true: false

    if (showTime && showFare && showPrice && showRent) {
      this.faresOuterTargets[index].classList.remove('hide')
      this.timesTargets[index].classList.remove('hide')
      this.pricesTargets[index].classList.remove('hide')
      this.rentsTargets[index].classList.remove('hide')
      this.areasTargets[index].classList.remove('hide')

      try {
        this.schoolRatingsUaTargets[index].classList.remove('hide')
      } catch(e) {
      }

    }
    else {
      this.faresOuterTargets[index].classList.add('hide')
      this.timesTargets[index].classList.add('hide')
      this.pricesTargets[index].classList.add('hide')
      this.rentsTargets[index].classList.add('hide')
      this.areasTargets[index].classList.add('hide')
      try {
        this.schoolRatingsUaTargets[index].classList.add('hide')
      } catch(e) {
      }
    }
  }


  filter() {
    this.timesTargets.forEach((el, i) => {
      let rowTime = Infinity

      if ((this.checkTrainTarget.checked && this.checkCarTarget.checked) || (!this.checkTrainTarget.checked && !this.checkCarTarget.checked)){
        rowTime = Math.min(parseInt(el.dataset.timeCar) || Infinity, parseInt(el.dataset.timeTrain) || Infinity) || ''
        this.hideFares(i, false)
      }
      else if (this.checkTrainTarget.checked) {
        rowTime = parseInt(el.dataset.timeTrain) || ''
        this.hideFares(i, false)
      }
      else {
        rowTime = parseInt(el.dataset.timeCar) || ''
        this.hideFares(i, true)
      }

      el.innerHTML = rowTime
      let rowFare = Math.min(parseFloat(el.dataset.trainFare) || Infinity)
      let rowPrice = Math.min(parseFloat(el.dataset.housePrice) || Infinity)
      let rowRent = Math.min(parseFloat(el.dataset.rent) || Infinity)
      this.hideRows(i, rowTime, rowFare, rowPrice, rowRent)
    })
  }

  initialSort() {
    if (this.sortIndexTarget.dataset.filtersSelectedIndex) {
      this.sortablesTargets.forEach((el, i) => {
        if (el.dataset.filtersIndex == this.sortIndexTarget.dataset.filtersSelectedIndex) {
          return el.click()
        }
      })
    }
  }

  sortWards(e) {
    e.preventDefault()
    let sorted = this.sortRows(e)
    this.resultTarget.innerHTML = this.renderWardRows(sorted, e)
    this.filter()
  }

  sortUas(e) {
    e.preventDefault()
    let sorted = this.sortRows(e)
    this.resultTarget.innerHTML = this.renderUaRows(sorted, e)
    this.filter()
  }

  sortRows(e) {
    let sortIndex = parseInt(e.currentTarget.dataset.filtersIndex)
    let rows = []
    this.timesTargets.forEach((el, i) =>  {
      rows.push([el.dataset.areaName,
                 parseInt(el.dataset.timeCar) || '',
                 parseInt(el.dataset.timeTrain) || '',
                 parseInt(el.dataset.housePrice) || '',
                 parseInt(el.dataset.trainFare) || '',
                 el.dataset.rgnName,
                 parseInt(el.dataset.rent) || ''
                 ])
    })

    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/sort#Sorting_with_map
    let mapped = rows.map((el, i) =>  {
      return {index: i, value: el[sortIndex]}
    })
    mapped.sort((a, b) => a.value - b.value)

    let sorted = mapped.map((el) => rows[el.index])
    return sorted
  }

  bookmark() {
    const id = window.location.href.split('#')[1]
    if (id) {
      try {
        document.getElementById(id).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
      } catch(e) {

      }
    }
  }


  connect() {
    this.filter()
    this.initialSort()
    this.bookmark()
  }


  // This is not used - so remove this function in next iteration.
  renderWardRows(rows, e) {
      let rowDivs = []
      rows.forEach((el, i) => {
        let price = el[3]
        
        try {
          price = el[3].toLocaleString()
        } catch (e) {
        }

        let rent = el[6]
        try {
          rent = el[6].toLocaleString()
        } catch (e) {
        }

        rowDivs.push(
        `<div data-target="filters.areas">${el[0]}</div>
        <div data-target="filters.times" data-time-car="${el[1]}" data-time-train="${el[2]}" data-train-fare="${el[4]}" data-house-price="${el[3]}" data-area-name="${el[0]}" data-rgn-name="${el[5]}" data-rent="${el[6]}">
             ${el[1]}
        </div>
        <div data-target="filters.faresOuter"><span data-target="filters.fares">${el[4]}</span></div>
        <div data-target="filters.prices"><a href="/webapp_shs/postcodes/${this.data.get('pcds')}/commutable_boroughs/${el[0]}/House Prices">${price}</a></div>
        <div data-target="filters.rents"><a href="/webapp_shs/postcodes/${this.data.get('pcds')}/commutable_boroughs/${el[0]}/Rents">${rent}</a></div>`
        )
      })

      const rowHTML = rowDivs.join('')

      let renderedHTML = `
          <div><h4>Ward</h4></div>
          <div data-target="filters.sortIndex" data-filters-selected-index="${e.currentTarget.dataset.filtersIndex}" id="area" data-turbolinks-permanent><h4>Travel Time</h4><sub>minutes</sub></div>
          <div>
            <span data-target="filters.fareHeader"><h4><a href="#" data-target="filters.sortables" data-filters-index="4" data-action="filters#sortWards">Train Fare</a></h4><sub>£/year</sub></span>
          </div>
          <div>
            <h4><a href="#" data-filters-index="3" data-target="filters.sortables" data-action="filters#sortWards">House Price</a></h4><sub>£</sub>
          </div>
          <div>
            <h4><a href="#" data-filters-index="6" data-target="filters.sortables" data-action="filters#sortWards">Rent</a></h4><sub>£</sub>
          </div>
          ${rowHTML}
        `
        return renderedHTML
  }

  renderUaRows(rows, e) {
    let rowDivs = []

    rows.forEach((el, i) => {
      let price = el[3]
      try {
        price = el[3].toLocaleString()
      } catch (e) {
      }

      let rent = el[6]
      try {
        rent = el[6].toLocaleString()
      } catch (e) {
      }

      const regex = /\s/gi
      const uaId = el[0].replace(regex, '-')
      rowDivs.push(
      `<div data-target="filters.areas" id="${uaId}"><a href="/webapp_shs/postcodes/${this.data.get('pcds')}/commutable_boroughs/${el[0]}">${el[0]}</a><span class="area2">${el[5]}</span></div>
      <div data-target="filters.times" data-time-car="${el[1]}" data-time-train="${el[2]}" data-train-fare="${el[4]}" data-house-price="${el[3]}" data-area-name="${el[0]}" data-rgn-name="${el[5]}" data-rent="${el[6]}">${el[1]}</div>
      <div data-target="filters.faresOuter"><span data-target="filters.fares">${el[4]}</span></div>
      <div data-target="filters.prices"><a href="/webapp_shs/postcodes/${this.data.get('pcds')}/commutable_boroughs/${el[0]}/House Prices">${price}</a></div>
      <div data-target="filters.rents"><a href="/webapp_shs/postcodes/${this.data.get('pcds')}/commutable_boroughs/${el[0]}/Rents">${rent}</a></div>`
      )
    })

    const rowHTML = rowDivs.join('')

    let renderedHTML = `
        <div><h4>Area</h4></div>
        <div data-target="filters.sortIndex" data-filters-selected-index="${e.currentTarget.dataset.filtersIndex}" id="area" data-turbolinks-permanent><h4>Travel Time</h4><sub>minutes</sub></div>
        <div>
          <span data-target="filters.fareHeader"><h4><a href="#" data-target="filters.sortables" data-filters-index="4" data-action="filters#sortUas">Train Fare</a></h4><sub>£/year</sub></span>
        </div>
        <div>
          <h4><a href="#" data-target="filters.sortables" data-filters-index="3" data-action="filters#sortUas">House Price</a></h4><sub>£</sub>
        </div>
        <div>
        <h4><a href="#" data-target="filters.sortables" data-filters-index="6" data-action="filters#sortUas">Rent</a></h4><sub>£</sub>
      </div>
        ${rowHTML}
      `
      return renderedHTML

  }

}
