import { Controller } from "stimulus"
import Chartist from "chartist"

export default class extends Controller {
  static targets = ['scores', 'charts', 'dotScores', 'dotCharts']

  drawScoresChart() {
    let options = {
      seriesBarDistance: 10,
      horizontalBars: true,
      low: 0,
      high: 100,
      height: '1em',
      fullWidth:true,
      chartPadding: {top: 0,right: 0,bottom: 0,left: 0},
      axisY: {showGrid: false, showLabel: false, offset: 0},
      axisX: {
        type: Chartist.AutoScaleAxis,
        onlyInteger: false,
        showGrid: false,
        showLabel: false,
        offset: 0
      }
    }

    this.scoresTargets.forEach((el, i) => {
      new Chartist.Bar(this.chartsTargets[i], {series: [[parseFloat(el.getAttribute('data-score-a'))], [parseFloat(el.getAttribute('data-score-b'))]]}, options)
    })
  }

  drawDotScoresChart() {
    let options = {
      showLine: false,
      height: '1em',
      fullWidth:true,
      chartPadding: {top: 5,right: 0,bottom: 0,left: 0},
      axisY: {showGrid: false, showLabel: false, offset: 0, type: Chartist.AutoScaleAxis},
      axisX: {
        type: Chartist.AutoScaleAxis,
        onlyInteger: false,
        showGrid: false,
        showLabel: false,
        offset: 5
      }
    }

    this.dotScoresTargets.forEach((el, i) => {
      let values_a = [{x: parseFloat(el.getAttribute('data-score-a')), y:0}]
      let values_b = JSON.parse(el.getAttribute('data-score-b')).map(x => {
                                                                  x.x = parseFloat(x.x)
                                                                  x.y = parseFloat(x.y)
                                                                  return x
                                                                })
      new Chartist.Line(this.dotChartsTargets[i], {series: [values_b, values_a]}, options)
    })
  }


  connect() {
    this.drawScoresChart()
    this.drawDotScoresChart()
  }

}
