import { Controller } from "stimulus"
import Chartist from "chartist"

export default class extends Controller {
  static targets = ['rank', 'charts']

  drawChart() {
    let options = {
      seriesBarDistance: 10,
      horizontalBars: true,
      height:'1em',
      width:'8ex',
      showPoint: false,
      fullWidth:true,
      chartPadding: {top: 0,right: 0,bottom: 0,left: 0},
      low:0,
      high: 1.0,
      axisX: {
        type: Chartist.AutoScaleAxis,
        onlyInteger: false,
        showGrid: false,
        showLabel: false,
        offset: 0
      },
      axisY: {showGrid: false, showLabel: false, offset: 0}
    }

    this.rankTargets.forEach((el, i) => {
      let values = []
      try {
        values = JSON.parse(el.getAttribute('data-crime-values')).map(x => parseFloat(x))
      }
      catch (e) {
        console.log(e)
      }

      new Chartist.Bar(this.chartsTargets[i], {series: [values]}, options) 
    })
  }


  connect() {
    this.drawChart()
  }

}
