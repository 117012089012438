import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['pcds']

  initialize() {
    const regex = RegExp('\/webapp_shs\/*$');
    if (regex.test(window.location.pathname)) {
      this.pcdsTarget.focus()
    }
  }

  submit(e) {
    e.preventDefault()
    const whiteSpace = /\s/gi;
    const pcds = this.pcdsTarget.value.replace(whiteSpace, '')
    const inwardCode = pcds.slice(-3)
    const outwardCode = pcds.slice(0, -3)

    if (outwardCode && inwardCode) {
      const pcdsFormatted = `${outwardCode.toUpperCase()} ${inwardCode.toUpperCase()}`
      Turbolinks.visit(`/webapp_shs/postcodes/${pcdsFormatted}`)
    }
  }

}
